import {
  BranchDetailsMessage,
  OrdersMessages,
  productMessage,
  staffMessage,
} from './../../../constants/messages.constant';
import {
  Input,
  Output,
  EventEmitter,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Injectable,
  NgZone,
  TemplateRef,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TimeSlotDialogComponent } from '../time-slot-dialog/time-slot-dialog.component';
import { DeliveryTypeDialogComponent } from '../delivery-type-dialog/delivery-type-dialog.component';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BranchService } from '../../services/branch.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
import { requireCheckboxesToBeCheckedValidator } from './require-checkboxes-to-be-checked.validator';
import { LeavePageService } from '../../../dialog/leave-page.service';
import { Observable, window } from 'rxjs';

import {
  BranchDetailsFormControls,
  ButtonConstant,
  ImgUrl,
  OrderDetailsFormControl,
} from 'src/app/pages/constants';
import { routes } from 'src/app/consts';
import { threadId } from 'worker_threads';

declare const google: any;

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-branch-detail',
  templateUrl: './branch-detail.component.html',
  styleUrls: ['./branch-detail.component.scss'],
})
export class BranchDetailComponent implements OnInit, AfterViewInit {
  @Input() list: any[];
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  checkedList: any[];
  currentSelected: {};

  public dataSource = [];
  public publicholidaysdelivery: any;
  public publicholidayspickup: any;
  public imgUrl = ImgUrl;
  public navigation: typeof routes = routes;
  public buttonConstant = ButtonConstant;
  public branchControl = BranchDetailsFormControls;
  public control = OrderDetailsFormControl;
  index: number;
  branchForm: any = FormGroup;
  submitted = false;
  id: number;
  serverData: any;
  deliveryAreaID: string;
  public timeSlotId: number;
  private geoCoder;
  public selection: string;
  buttonActive: boolean = true;
  maxEmailMessage = false;
  maxPhoneMessage = false;
  openingTimesDeliveryStd: string;
  openingTimesPickupStd: string;
  openingTimesDeliveryAlt: string;
  openingTimesPickupAlt: string;
  standarddelivery: any;
  standardPickup: any;
  alternatePickup: any;
  alternateDelivery;
  productList: any = [];
  public validation: boolean;
  data: Array<Object> = [];
  fieldsvalues: Object;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  addressValid: boolean;
  showDropDown: boolean;
  onlyForTableOrderCheck = false;
  onlyForCompartmentOrderCheck: boolean = false;

  isPickupValidator: boolean = false;
  pickupValidatorMessage: string;
  isTimeslotDefined: boolean = false;

  branchdeliveryareasId: any;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private branchService: BranchService,
    private notifyService: NotificationService,
    private mapsAPILoader: MapsAPILoader,
    private leavePageService: LeavePageService,
    private _ngZone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {
    // this.id = this.route.snapshot.params['id']; //Getting route parameter
    // debugger;
    this.checkedList = [];
    if (this.id) {
      // this.branchService.formData = '';
    }

    console.log(this.route.snapshot, "this.route.snapshot")
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit running");
    this.getAllProduct();
    var options = {
      types: [],
      componentRestrictions: { country: ['de', 'in'] },
    };
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        options
      );
      autocomplete.addListener('place_changed', () => {
        let place = autocomplete.getPlace();
        this.branchService.deliveryAddressGeometry = place.geometry;

        this.branchForm.get('address').setValue(place.formatted_address);
        this.checkValidAddress(place.formatted_address, true);
      });
    });
    //****** when go to add delivery area map store form data to branch service form array and after return back patch form values */
    // if (this.id == undefined) {
    if (this.branchService.formData) {
      var response = this.branchService.formData;
      this.setFormArrayValues(response); // set editable emails or phones
      this.branchForm.patchValue(response); // get branch details
      this.setDeliveryOptions(response.freeShipping, response.localPickup);
      this.branchForm.controls.branchdeliveryareasId.setValue(
        this.branchService.deliveryAreaID
      );
      // ******* show selected radio
      var openingTimesDeliveryStandard = '';

      if (response.isstandarddelivery == 'true') {
        // standard delivery
        openingTimesDeliveryStandard = 'deliveryStandard';
      } else if (response.isalternatedelivery == 'true') {
        // alter delivery
        openingTimesDeliveryStandard = 'deliveryAlternative';
      }
      this.branchForm.controls['openingTimesDelivery'].setValue(
        openingTimesDeliveryStandard
      ); // set selected

      var openingTimesPickupStandard = '';
      if (response.isstandardpickup == 'true') {
        // standard pickup
        openingTimesPickupStandard = 'pickUpStandard';
      } else if (response.isalternatepickup == 'true') {
        // alter pickup
        openingTimesPickupStandard = 'pickUpAlternative';
      }
      this.branchForm.controls['openingTimesPickup'].setValue(
        openingTimesPickupStandard
      );
      // ******* show selected radio
    }
    // }
  }

  selectProduct(id) {
    var result = this.productList.find(({ uid }) => uid == id);
  }

  getSelectedValue(status: Boolean, value: String, uid: number) {
    // if(status){
    //   this.checkedList.push(value);
    // }else{
    //     var index = this.checkedList.indexOf(value);
    //     this.checkedList.splice(index,1);
    // }

    if (uid == 0) {
      var data = this.productList;
      this.productList = [];
      data.forEach((element) => {
        this.productList.push({
          uid: element.uid,
          name: element.name,
          checked: true,
        });
        this.checkedList.push({
          productid: element.uid,
          name: element.name,
          status: 1,
          selected: true,
        });
      });
    } else {
      let ui = this.productList.findIndex((item) => item.uid == uid);

      this.productList[ui].checked = !status;

      const allCheckedExceptFirst = this.productList.slice(1).every(product => product.checked);

      if (status) this.productList[0].checked = false;
      else if (allCheckedExceptFirst) this.productList[0].checked = true;

      this.checkedList.push({
        productid: this.productList[ui].uid,
        name: this.productList[ui].name,
        status: !status ? 1 : 2,
        selected: !status,
      });

      // this.checkedList.push({
      //   productid: uid,
      //   name: value,
      //   status: !status ? 1 : 2,
      //   selected: !status,
      // });
      // this.currentSelected = {
      //   uid: uid,
      //   name: value,
      //   status: !status ? 1 : 2,
      //   checked: !status,
      // };
    }
    // this.branchForm.controls['selectedproducts'].setValue(
    //   this.checkedList
    // );

    (this.branchForm.get('selectedproducts') as FormArray).push(
      this.getproduct(this.currentSelected)
    );

    localStorage.setItem('productList', JSON.stringify(this.productList))

    // this.branchForm.controls.selectedproducts.setValue(this.checkedList);

    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }

  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
  getAllProduct() {

    this.branchService.getAllProductsForDropdown().subscribe(
      (data: any) => {
        // console.log(data, "data ------272")
        // this.productList = data;
        this.productList.push({ uid: 0, name: 'Alle', checked: false });

        data.forEach((element) => {

          let br = JSON.parse(element.branchIds);
          // console.log(br, "br", this.id)
          // "--"
          let selected = br.find(
            ({ branchId, status }) => branchId == this.id && status == 1
          );
          // console.log(selected, "selected276")
          if (selected) {
            // console.log(selected, "selected")
            this.productList.push({
              uid: element.uid,
              name: element.name,
              checked: selected.status == 1,
            });
          } else
            this.productList.push({
              uid: element.uid,
              name: element.name,
              checked: false,
            });
        });

        let ui = this.productList.findIndex(
          (item) => item.uid != 0 && item.checked == false
        );
        if (ui == -1) this.productList[0].checked = true;


        if (JSON.parse(localStorage.getItem('productList'))) {
          this.productList = JSON.parse(localStorage.getItem('productList'));
        }



        console.log(this.productList, "productList")
      },
      (err) => {
        console.log(err);
      }
    );

  }
  checkValidAddress(address, valid) {
    // add valid address validation
    if (address.length < 5) {
      return;
    }

    if (valid) {
      this.branchForm.get('address').setErrors(null); // remove valid address validation
    } else {
      this.branchForm.get('address').setErrors({ invalid: true });
    }

    this._ngZone.run(() => {
      this.addressValid = valid;
    });
  }

  // ------------------------------------------------------------------------------------------------
  // @ If this is update form, get user details and update form group
  // ------------------------------------------------------------------------------------------------
  public parsedata(val: any) {
    if (val) {
      return JSON.parse(val);
    }
    return '';
  }
  getBranchDetails(index: number) {
    // console.log('index===275===>',index);
    this.branchService.getBranchById(index).subscribe(
      (response: any) => {
        console.log('getBranchById [branch detail] ===>', response);
        this.branchdeliveryareasId = response.branchdeliveryareasId;
        if (response.timeslot != null) {
          this.standarddelivery = this.parsedata(
            response.timeslot.standarddelivery
          );
          this.alternatePickup = this.parsedata(
            response.timeslot.alternatepickup
          );
          this.standardPickup = this.parsedata(
            response.timeslot.standardpickup
          );
          this.alternateDelivery = this.parsedata(
            response.timeslot.alternatedelivery
          );
        }

        if (!this.branchService.formData) {
          this.setFormArrayValues(response); // set editable emails or phones
          this.branchForm.patchValue(response); // get branch details
          this.setDeliveryOptions(response.freeShipping, response.localPickup);

          // this.branchForm.value.forTableQROnly = response.onlytableorders;
          this.branchForm
            .get('forTableQROnly')
            .setValue(response.onlytableorders);
          this.onlyForTableOrderCheck = response.onlytableorders;

          // this.branchForm
          // .get('forContainerOrderOnly')
          // .setValue(response.onlyContainerOrders);
          // this.onlyForCompartmentOrderCheck = response.onlyContainerOrders;

          this.branchForm
            .get('containerId')
            .setValue(response.kioskeContainerId);

          this.onlyForCompartmentOrderCheck = (response.kioskeContainerId) ? true : false;

          this.branchForm.get('online').setValue(response.online);

          // ******* show selected radio
          var openingTimesDeliveryStandard = '';

          if (response.timeslot.isstandarddelivery == true) {
            // standard delivery
            openingTimesDeliveryStandard = 'deliveryStandard';
          } else if (response.timeslot.isalternatedelivery == true) {
            // alter delivery
            openingTimesDeliveryStandard = 'deliveryAlternative';
          }
          this.branchForm.controls['openingTimesDelivery'].setValue(
            openingTimesDeliveryStandard
          ); // set selected

          var openingTimesPickupStandard = '';
          if (response.timeslot.isstandardpickup == true) {
            // standard pickup
            openingTimesPickupStandard = 'pickUpStandard';
          } else if (response.timeslot.isalternatepickup == true) {
            // alter pickup
            openingTimesPickupStandard = 'pickUpAlternative';
          }
          this.branchForm.controls['openingTimesPickup'].setValue(
            openingTimesPickupStandard
          );
          // ******* show selected radio
          this.publicholidaysdelivery = this.parsedata(
            response.timeslot?.publicholidaysdelivery
          );
          this.publicholidayspickup = this.parsedata(
            response.timeslot?.publicholidayspickup
          );
          this.timeSlotId = response.timeslot?.uid;
          if (this.timeSlotId) {
            this.isTimeslotDefined = true;
            console.log("isTimeslotDefined:", this.isTimeslotDefined);
            console.log("onlyForCompartmentOrderCheck:", this.onlyForCompartmentOrderCheck);
          }
        }
        this.changeDeliveryType();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ Set form group key and if edit category - get detail by id
  // ------------------------------------------------------------------------------------------------
  ngOnInit() {
    console.log("ngoninitrunning----->>");
    this.route.params.subscribe(params => {
      this.id = params['id'];  // Get the 'id' from the URL parameter
      // You can now use 'this.id' within the component
    });
    console.log("id [oninit]:", this.id);

    this.getAllProduct();

    this.branchForm = this.fb.group({
      uid: ['0'],
      deliverytypeid: [''],
      branchdeliveryareasId: [this.branchService.deliveryAreaID],
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(/^((?!\s{2,}).)*$/),
        ],
      ],
      address: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(200),
          Validators.pattern(/^((?!\s{2,}).)*$/),
        ],
      ],
      //   postcode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      phones: this.fb.array([]),
      selectedproducts: this.fb.array([]),
      emails: this.fb.array([]),
      timeslotId: [''],
      online: [''],
      isalternatedelivery: [],
      isalternatepickup: [],
      isstandarddelivery: [],
      isstandardpickup: [],
      openingTimesPickup: [''],
      openingTimesDelivery: [''],
      deliveryOptions: new FormGroup(
        {
          delivers: new FormControl(false),
          pickup: new FormControl(false),
        }
        // requireCheckboxesToBeCheckedValidator()
      ),
      forTableQROnly: false,
      // forContainerOrderOnly: false,
      containerId: [
        '',
        [
          // Validators.required,
          // Validators.minLength(2),
          // Validators.maxLength(30),
          // Validators.pattern(/^((?!\s{2,}).)*$/),
        ],
      ],
    });
    if (this.id) {
      this.getBranchDetails(this.id);
    } else {
      if (!this.branchService.formData) {
        // if form data not store to service file
        (this.branchForm.get('emails') as FormArray).push(this.getEmail(null)); // add default 1 email when add
        (this.branchForm.get('phones') as FormArray).push(this.getPhone(null)); // add default 1 email when add
      }
    }

    this.updateValidation();
    // console.log('this.branchForm', this.branchForm.controls)
    // this.listTimeSlotes(); // get branch
  }

  onCheckboxClick(event: MouseEvent): void {

    if (!this.branchForm.get('online').value) {
      this.branchService.goLiveBranchCheck(this.id).subscribe(
        (response) => {
          console.log("response", response)
          if (response) {
            this.branchForm.get('online').setValue(true);

            // this.branchForm.setValue(true);
          } else {
            if (this.id) {
              this.notifyService.showError(
                this.translate.instant('pages.branches.branchDetail.goliveID'),
                this.translate.instant('Online gehen')
              );

            } else {
              this.notifyService.showError(
                this.translate.instant('pages.branches.branchDetail.goliveNoID'),
                this.translate.instant('Online gehen')
              );

            }
            this.branchForm.get('online').setValue(false);
            // this.branchForm.setValue(false);
          }
        },
        (error) => {
          console.error('API call failed', error);
          this.branchForm.get('online').setValue(false);
          // this.branchForm.setValue(false);
        }
      );
    }
    console.log(this.branchForm.get('online').value, "this.branchForm.getValue('online');")

  }

  // ------------------------------------------------------------------------------------------------
  // @ Get email array from api and formatted or show
  // ------------------------------------------------------------------------------------------------
  setFormArrayValues(data) {
    let emailArray = this.parsedata(data.email); // convert string to equivalent obj
    for (let email of emailArray) {
      (this.branchForm.get('emails') as FormArray).push(this.getEmail(email));
    }

    var phoneArray = this.parsedata(data.phone);
    for (let phone of phoneArray) {
      (this.branchForm.get('phones') as FormArray).push(this.getPhone(phone));
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ Set EMAIL fields start
  // ------------------------------------------------------------------------------------------------
  getEmail(email = null): FormGroup {
    // console.log('email', email)
    return this.fb.group({
      email: [email],
    });
  }
  getproduct(product = null): FormGroup {
    return this.fb.group({
      product: [product],
    });
  }

  // ------------------------------------------------------------------------------------------------
  // @ Add EMAIL fields start
  // ------------------------------------------------------------------------------------------------
  addEmail(event) {
    this.maxEmailMessage = false;
    this.branchForm.controls['emails'].markAllAsTouched();
    if (
      this.emailValidItemData().success == 1 &&
      this.branchForm.value.emails.length < 4
    ) {
      // if valid data then add new row
      (this.branchForm.get('emails') as FormArray).push(this.getEmail());
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ Delete remove EMAIL fields start
  // ------------------------------------------------------------------------------------------------
  deleteEmailField(index) {
    this.emailFields.removeAt(index);
  }

  get emailFields() {
    return this.branchForm.get('emails') as FormArray;
  }

  // ------------------------------------------------------------------------------------------------
  // @ Email array validation for before insert
  // ------------------------------------------------------------------------------------------------
  emailValidItemData() {
    var length = this.branchForm.value.emails.length;

    if (length >= 4) {
      this.maxEmailMessage = true;
      return {
        success: 1,
        message: this.translate.instant(
          'pages.branches.branchDetail.inputValidation.customEmailValid.maxEmail'
        ),
        index: this.branchForm.value.emails.length - 1,
      };
    }

    let psErrMsg = '';
    for (let i = 0; i < this.branchForm.value.emails.length; i++) {
      var checkEmail = this.checkEmail(
        this.branchForm.value.emails[i].email,
        i
      );
      if (!checkEmail.success) {
        // check valid email
        psErrMsg = checkEmail.message;
        return { success: 0, message: psErrMsg, index: i };
      }
    }
    if (psErrMsg == '') {
      this.branchForm.controls['emails'].markAsUntouched();
      return { success: 1, message: '', index: null };
    }
  }

  checkEmail(email, i) {
    // validate Email
    if (email == null || email == '') {
      return {
        success: 0,
        message: this.translate.instant(BranchDetailsMessage.emailRequired),
        index: i,
      };
    }
    const validEmailRegEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validEmailRegEx.test(email)) {
      return { success: 1, message: '', index: null };
    } else {
      return {
        success: 0,
        message: this.translate.instant(BranchDetailsMessage.customEmailValid),
        index: i,
      };
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ Set PHONE fields start
  // ------------------------------------------------------------------------------------------------
  getPhone(phone = null): FormGroup {
    return this.fb.group({
      phone: [phone],
    });
  }

  // ------------------------------------------------------------------------------------------------
  // @ Add PHONE fields start
  // ------------------------------------------------------------------------------------------------
  addPhone(event) {
    this.maxPhoneMessage = false;
    this.branchForm.controls['phones'].markAllAsTouched();
    if (
      this.phoneValidItemData().success == 1 &&
      this.branchForm.value.phones.length < 4
    ) {
      (this.branchForm.get('phones') as FormArray).push(this.getPhone());
    }
  }
  // ------------------------------------------------------------------------------------------------
  // @ Delete PHONE fields start
  // ------------------------------------------------------------------------------------------------
  deletePhoneField(index) {
    this.phoneFields.removeAt(index);
  }

  get phoneFields() {
    return this.branchForm.get('phones') as FormArray;
  }
  // ------------------------------------------------------------------------------------------------
  // @ Phone array validation for before insert
  // ------------------------------------------------------------------------------------------------
  phoneValidItemData() {
    // if (this.branchForm.value.phones.length >= 4) {
    //   this.maxPhoneMessage = true;
    //   return {
    //     success: 1,
    //     message: this.translate.instant(BranchDetailsMessage.maxPhone),
    //     index: this.branchForm.value.phones.length - 1,
    //   };
    // } else {
    let psErrMsg = '';
    for (let i = 0; i < this.branchForm.value.phones.length; i++) {
      var checkPhone = this.checkPhone(
        this.branchForm.value.phones[i].phone,
        i
      );
      if (!checkPhone.success) {
        psErrMsg = checkPhone.message;
        return { success: 0, message: psErrMsg, index: i };
      }
    }
    if (psErrMsg == '') {
      this.branchForm.controls['phones'].markAsUntouched();
      return { success: 1, message: '', index: null };
    }
    // }
  }

  checkPhone(phone, i) {
    // validate phone
    if (phone == null || phone == '') {
      return {
        success: 0,
        message: this.translate.instant(BranchDetailsMessage.phoneRequired),
        index: i,
      };
    }
    const validPhoneRegEx = /^[0-9]*$/;
    // if (phone.length > 20) {
    //   return {
    //     success: 0,
    //     message: this.translate.instant(BranchDetailsMessage.maxLength),
    //     index: i,
    //   };
    // } else if (phone.length < 3) {
    //   return {
    //     success: 0,
    //     message: this.translate.instant(BranchDetailsMessage.minLength),
    //     index: i,
    //   };
    //} else
    if (validPhoneRegEx.test(phone)) {
      return { success: 1, message: '', index: null };
    } else {
      return {
        success: 0,
        message: this.translate.instant(BranchDetailsMessage.phoneValid),
        index: i,
      };
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ when edit branch show selected delivery types
  // ------------------------------------------------------------------------------------------------
  setDeliveryOptions(freeShipping, localPickup) {
    if (freeShipping == undefined) {
      return;
    }

    if (freeShipping == true && localPickup == true) {
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).setValue({
        delivers: true,
        pickup: true,
      });
    } else if (freeShipping == true && localPickup == false) {
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).setValue({
        delivers: true,
        pickup: false,
      });
    } else if (freeShipping == false && localPickup == true) {
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).setValue({
        delivers: false,
        pickup: true,
      });
    }
  }

  onChangeDeliveryOption(data: any) {
    console.log("Value:", data.value);
    const value: string = data.value;
    if (value) {
      this.isPickupValidator = false;
    }
  }

  changeDeliveryType() {

    console.log("value.pickup:", this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value.pickup);


    if (
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
        .delivers &&
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
        .pickup
    ) {
      this.branchForm
        .get('deliverytypeid')
        .setValidators([Validators.required]);
      this.branchForm.get('deliverytypeid').updateValueAndValidity();
      this.branchForm
        .get('branchdeliveryareasId')
        .setValidators([Validators.required]);
      this.branchForm.get('branchdeliveryareasId').updateValueAndValidity();
      this.branchForm
        .get('openingTimesPickup')
        .setValidators([Validators.required]);
      this.branchForm.get('openingTimesPickup').updateValueAndValidity();
      this.branchForm
        .get('openingTimesDelivery')
        .setValidators([Validators.required]);
      this.branchForm.get('openingTimesDelivery').updateValueAndValidity();
      // this.branchForm.get('openingTimesPickup').setErrors(null);
      // this.branchForm.get('openingTimesDelivery').setErrors(null);

      return;
    } else if (
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
        .delivers
    ) {
      // this.isPickupValidator = true;
      // this.pickupValidatorMessage = "Bitte wählen Sie eine Lieferoption.";  //Please select a delivery option.

      this.branchForm
        .get('deliverytypeid')
        .setValidators([Validators.required]);
      this.branchForm.get('deliverytypeid').updateValueAndValidity();
      this.branchForm
        .get('branchdeliveryareasId')
        .setValidators([Validators.required]);
      this.branchForm.get('branchdeliveryareasId').updateValueAndValidity();
      this.branchForm
        .get('openingTimesDelivery')
        .setValidators([Validators.required]);
      this.branchForm.get('openingTimesDelivery').updateValueAndValidity();
      this.branchForm.get('openingTimesPickup').setErrors(null);

      return;
    } else if (
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
        .pickup
    ) {
      this.isPickupValidator = !this.isPickupValidator;

      this.cdr.detectChanges();

      // this.pickupValidatorMessage = "Bitte wählen Sie eine Abholoption.";   // Please select a pickup option.

      this.branchForm.get('deliverytypeid').setErrors(null);
      this.branchForm.get('branchdeliveryareasId').setErrors(null);
      this.branchForm.get('openingTimesDelivery').setErrors(null);
      return;
    } else if (
      !this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
        .delivers
    ) {
      this.branchForm.get('deliverytypeid').setValidators();
      this.branchForm.get('deliverytypeid').updateValueAndValidity();
      this.branchForm.get('branchdeliveryareasId').setValidators();
      this.branchForm.get('branchdeliveryareasId').updateValueAndValidity();
      this.branchForm.get('openingTimesDelivery').setValidators();
      this.branchForm.get('openingTimesDelivery').updateValueAndValidity();
      this.branchForm.get('openingTimesPickup').setErrors(null);
      return;
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ check validate and Submit form details
  // ------------------------------------------------------------------------------------------------
  // submitForm(valid = true, templateRef: TemplateRef<any>){
  //   console.log("branchForm:", this.branchForm.value);
  // }

  submitForm(valid = true, templateRef: TemplateRef<any>) {
    if (valid == true) {
      if (
        this.branchForm.valid !== true ||
        this.emailValidItemData().success !== 1 ||
        this.phoneValidItemData().success !== 1
      ) {
        this.branchForm.markAllAsTouched();
        return false;
      }

      if (
        !this.onlyForTableOrderCheck &&
        !this.onlyForCompartmentOrderCheck &&
        !(
          this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
            .delivers ||
          this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
            .pickup
        )
      ) {
        this.dialog.open(templateRef, { width: '400px' });
        return false;
      }
    }

    this.buttonActive = false;
    var emailArray = this.getFormsEmailArray();
    var phoneArray = this.getFormsPhoneArray();
    var isstandarddelivery =
      this.branchForm.controls['openingTimesDelivery'].value ==
        'deliveryStandard'
        ? 'true'
        : 'false';
    var isstandardpickup =
      this.branchForm.controls['openingTimesPickup'].value == 'pickUpStandard'
        ? 'true'
        : 'false';
    var isalternatepickup =
      this.branchForm.controls['openingTimesPickup'].value ==
        'pickUpAlternative'
        ? 'true'
        : 'false';
    var isalternatedelivery =
      this.branchForm.controls['openingTimesDelivery'].value ==
        'deliveryAlternative'
        ? 'true'
        : 'false';
    console.log('712');

    var data = {
      uid: this.branchForm.value.uid ? this.branchForm.value.uid : 0,
      name: this.branchForm.value.name, // [0].toUpperCase() + this.branchForm.value.name.substr(1).toLowerCase(),
      address: this.branchForm.value.address,
      email: emailArray,
      phone: phoneArray,
      freeShipping: this.branchForm.get(
        BranchDetailsFormControls.deliveryOptions
      ).value.delivers
        ? this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
          .delivers
        : false,
      localPickup: this.branchForm.get(
        BranchDetailsFormControls.deliveryOptions
      ).value.pickup
        ? this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
          .pickup
        : false,
      deliverytypeid: this.branchForm.value.deliverytypeid
        ? this.branchForm.value.deliverytypeid
        : null,
      online: this.branchForm.value.online ? this.branchForm.value.online : 0,
      branchdeliveryareasId: this.branchForm.value.branchdeliveryareasId
        ? this.branchForm.value.branchdeliveryareasId
        : null,
      tenantId: '00000000-0000-0000-0000-000000000000',
      //"postcode": this.branchForm.value.postcode,
      timeslotId: this.branchForm.value.timeslotId
        ? this.branchForm.value.timeslotId
        : null,
      isstandarddelivery: isstandarddelivery ? isstandarddelivery : '',
      isstandardpickup: isstandardpickup ? isstandardpickup : '',
      isalternatedelivery: isalternatedelivery ? isalternatedelivery : '',
      isalternatepickup: isalternatepickup ? isalternatepickup : '',
      Onlytableorders: this.branchForm.value.forTableQROnly,
      // OnlyContainerOrders: this.branchForm.value.forContainerOrderOnly,
      // kioskeContainerId: this.branchForm.value.containerId    // kioskeContainerId
      kioskeContainerId: this.branchForm.value.containerId ? this.branchForm.value.containerId.trim() : null
    };

    if (this.branchForm.value.containerId) {
      data.localPickup = true;
      // this.onlyForCompartmentOrderCheck = true;
    }

    if (valid == false) {
      this.branchService.formData = data;
      return;
    } else {
      if (this.id) {
        //update
        var obj = {
          branch: data,
          branchProducts: this.checkedList,
        };
        this.updateBranch(obj);
        this.branchService.formData = '';
        this.branchService.deliveryAreaID = '';
        this.branchService.deliverytypeid = '';
      } else {
        //add
        console.log(this.checkedList, "this.checkedList")
        var obj = {
          branch: data,
          branchProducts: this.checkedList,
        };
        // console.log('obj===757==>', obj);
        this.addBranch(obj);
        this.branchService.formData = '';
        this.branchService.deliveryAreaID = '';
        this.branchService.deliverytypeid = '';
      }
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ update
  // ------------------------------------------------------------------------------------------------
  updateBranch(data) {
    data.branchProducts.pop[0];
    console.log('data', data);
    localStorage.removeItem("productList")
    this.branchService.updateBranch(data).subscribe(
      (response: any) => {
        if (response.success == true) {
          // updated
          this.notifyService.showSuccess(
            this.translate.instant(OrdersMessages.update),
            this.translate.instant(OrdersMessages.success)
          );
          this.router.navigate([this.navigation.BRANCHES]);
        } else if (
          response.success == false &&
          response.code == 'branch_Exist'
        ) {
          // already exist
          this.notifyService.showError(
            this.translate.instant(BranchDetailsMessage.branchExist),
            this.translate.instant(OrdersMessages.error)
          );
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ add
  // ------------------------------------------------------------------------------------------------
  addBranch(data) {
    localStorage.removeItem("productList")
    this.branchService.addBranch(data).subscribe(
      (response: any) => {
        if (
          response.success == true &&
          response.code == BranchDetailsFormControls.response_Code.branch_Added
        ) {
          this.notifyService.showSuccess(
            this.translate.instant(productMessage.add),
            this.translate.instant(OrdersMessages.success)
          );
          this.router.navigate([this.navigation.BRANCHES]);
        } else if (
          response.success == false &&
          response.code == BranchDetailsFormControls.response_Code.branch_Exist
        ) {
          this.notifyService.showError(
            this.translate.instant(BranchDetailsMessage.branchExist),
            this.translate.instant(OrdersMessages.error)
          );
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ Delete
  // ------------------------------------------------------------------------------------------------
  deleteBranch(branchId: number) {
    if (confirm(this.translate.instant(OrdersMessages.confirmDelete))) {
      this.branchService.deleteBranch(branchId).subscribe(
        (response: any) => {
          if (
            response.success == true &&
            response.code == BranchDetailsMessage.responseCode.branch_Deleted
          ) {
            this.notifyService.showSuccess(
              this.translate.instant(OrdersMessages.delete),
              this.translate.instant(OrdersMessages.success)
            );
            this.router.navigate([this.navigation.BRANCHES]);
          } else if (
            response.success == false &&
            response.code == BranchDetailsMessage.responseCode.branch_Associated
          ) {
            this.notifyService.showError(
              this.translate.instant(BranchDetailsMessage.branchAssociated),
              this.translate.instant(OrdersMessages.error)
            );
          }
        },
        (err) => {
          // this.errors = err;
        }
      );
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ Create email array from obj
  // ------------------------------------------------------------------------------------------------
  getFormsEmailArray() {
    var emailArray = [];
    for (let i = 0; i < this.branchForm.value.emails.length; i++) {
      emailArray.push(this.branchForm.value.emails[i].email);
    }
    return JSON.stringify(emailArray); // put array in quote "[]"
  }

  // ------------------------------------------------------------------------------------------------
  // @ Create email array from obj
  // ------------------------------------------------------------------------------------------------
  getFormsPhoneArray() {
    var phoneArray = [];
    for (let i = 0; i < this.branchForm.value.phones.length; i++) {
      phoneArray.push(this.branchForm.value.phones[i].phone);
    }
    return JSON.stringify(phoneArray); // put array in quote "[]"
  }

  // ------------------------------------------------------------------------------------------------
  // @ Open dialog for delivery type
  // ------------------------------------------------------------------------------------------------
  openDeliveryTypeDialog() {
    if (
      this.branchForm.get(BranchDetailsFormControls.deliveryOptions).value
        .delivers == false
    ) {
      this.notifyService.showWarning(
        this.translate.instant(BranchDetailsMessage.deliveryOptions),
        this.translate.instant(OrdersMessages.warning)
      );
      return;
    }

    const dialogRef = this.dialog.open(DeliveryTypeDialogComponent, {
      width: '500px',
      data: {
        deliverytypeid: this.branchForm.value.deliverytypeid,
        delivers: this.branchForm.get(BranchDetailsFormControls.deliveryOptions)
          .value.delivers,
        pickup: this.branchForm.get(BranchDetailsFormControls.deliveryOptions)
          .value.pickup,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.branchForm.controls.deliverytypeid.setValue(result.uid);
        this.branchService.deliverytypeid = result.uid;
      }
    });
  }

  createQRCode() {
    if (this.id) {
      localStorage.setItem('branchId', this.id.toString());
      //this.router.navigate([routes.QRCODES]);

      this.router.navigate([routes.QRCODES + '/' + this.id]);
    }
    //  else {
    //   this.router.navigate([routes.QRCODES_ADD]);
    // }
  }

  // ------------------------------------------------------------------------------------------------
  // @ Open dialog for time slot
  // ------------------------------------------------------------------------------------------------
  openDialog(dialogType, data = [], publicHolidayId = 0): void {
    var isstandardpickup =
      this.branchForm.controls['openingTimesPickup'].value === 'pickUpStandard'
        ? 'true'
        : 'false';
    var isalternatepickup =
      this.branchForm.controls['openingTimesPickup'].value ==
        'pickUpAlternative'
        ? 'true'
        : 'false';
    var isstandarddelivery =
      this.branchForm.controls['openingTimesDelivery'].value ==
        'deliveryStandard'
        ? 'true'
        : 'false';
    var isalternatedelivery =
      this.branchForm.controls['openingTimesDelivery'].value ==
        'deliveryAlternative'
        ? 'true'
        : 'false';

    const dialogRef = this.dialog.open(TimeSlotDialogComponent, {
      width: '500px',
      data: {
        slotId: this.branchForm.value.timeslotId
          ? this.branchForm.value.timeslotId
          : 0,
        branchId: this.id,
        dialogType: dialogType,
        data: data,
        publicHolidayId: publicHolidayId,
        isstandardpickup: isstandardpickup,
        isalternatepickup: isalternatepickup,
        isstandarddelivery: isstandarddelivery,
        isalternatedelivery: isalternatedelivery,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data.dialogType === 'openingTimesPublicHolidaysDelivery') {
        this.publicholidaysdelivery = this.parsedata(
          result.data.publicholidaysdelivery
        );
      } else if (
        result.data.dialogType === 'openingTimesPublicHolidaysPickup'
      ) {
        this.publicholidayspickup = this.parsedata(
          result.data.publicholidayspickup
        );
      } else if (result.data.dialogType === 'openingTimesDeliveryStandard') {
        this.standarddelivery = this.parsedata(result.data.standarddelivery);
      } else if (result.data.dialogType === 'openingTimesDeliveryAlternative') {
        this.alternateDelivery = this.parsedata(result.data.alternatedelivery);
      } else if (result.data.dialogType === 'openingTimesPickupStandard') {
        this.standardPickup = this.parsedata(result.data.standardpickup);
      } else if (result.data.dialogType === 'openingTimesPickupAlternative') {
        this.alternatePickup = this.parsedata(result.data.alternatepickup);
      } else {
        console.log('error');
      }
      if (result) {
        this.branchForm.controls.timeslotId.setValue(result.uid);

        // Use NgZone to trigger change detection after async result
        this._ngZone.run(() => {
          if (this.branchForm.controls.timeslotId) {
            this.isTimeslotDefined = true;
          }
          this.cdr.detectChanges();
        });
      }
    });
  }

  onlyTableOrder() {
    this.onlyForTableOrderCheck = !this.onlyForTableOrderCheck;
    // this.branchForm.value.forTableQROnly = this.onlyForTableOrderCheck;
    this.branchForm.get('forTableQROnly').setValue(this.onlyForTableOrderCheck);
  }

  onlyCompartmentOrder() {
    this.onlyForCompartmentOrderCheck = !this.onlyForCompartmentOrderCheck;
    // this.branchForm.get('forContainerOrderOnly').setValue(this.onlyForCompartmentOrderCheck);

    this.updateValidation();
  }

  updateValidation() {  // to conditionally update the form validation.
    const containerId = this.branchForm.get('containerId');

    // if (containerId) {
    //   this.onlyForCompartmentOrderCheck = true;
    // }
    // console.log("onlyForCompartmentOrderCheck", this.onlyForCompartmentOrderCheck);

    if (this.onlyForCompartmentOrderCheck) {
      containerId?.setValidators([
        Validators.required,
        Validators.pattern(/^\S*$/), // Regex to prevent spaces
      ]);
    } else {
      containerId?.clearValidators();
      this.branchForm
        .get('containerId')
        .setValue(null);
    }
    containerId?.updateValueAndValidity();
  }

  // ------------------------------------------------------------------------------------------------
  // @ Leave page confirmation
  // ------------------------------------------------------------------------------------------------
  canDeactivate(): Observable<boolean> | boolean {
    if (!!this.branchForm && this.branchForm.dirty && this.buttonActive) {
      return this.leavePageService.confirm(
        this.translate.instant(staffMessage.leavePageDialog)
      );
    }
    return true;
  }
}
