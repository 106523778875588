import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BranchService } from '../../services/branch.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../core/services/notification.service';
import { OrdersMessages, BranchDetailsMessage, productMessage, ImgUrl, ButtonConstant } from 'src/app/pages/constants';
import { routes } from 'src/app/consts';

declare const google: any;
@Component({
  selector: 'app-branch-delivery-area',
  templateUrl: './branch-delivery-area.component.html',
  styleUrls: ['./branch-delivery-area.component.scss'],
})
export class BranchDeliveryAreaComponent {
  public imgUrl = ImgUrl;
  public buttonConstant = ButtonConstant;
  public navigation: typeof routes = routes;

  public updatedLatLng: any;
  public centerLat: any;
  public centerLong: any;
  public lat = 13.762783172152155;
  public lng = 100.50996533481295;
  public zoom = 13;
  paths = [];
  pointList: { lat: number; lng: number }[] = [];
  drawingManager: any;
  selectedShape: any;
  selectedArea: any = 0;
  areaID: number;
  updateArea: boolean = false;
  show: boolean = false;

  constructor(
    private location: Location,
    private branchService: BranchService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notifyService: NotificationService,
    private router: Router
  ) {
    // this.areaID = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.areaID = this.route.snapshot.params['id'];

    if (this.areaID) {
      this.getBranchDeliveryAreas(this.areaID);
    } else if (this.branchService.deliveryAddressGeometry) {
      this.lat = this.branchService.deliveryAddressGeometry.location.lat();
      this.lng = this.branchService.deliveryAddressGeometry.location.lng();
      this.setMapCurrentPosition(this.lat, this.lng);
      this.show = true;
    } else {
      this.setMapCurrentPosition('', '');
      this.show = true;
    }
  }

  getBranchDeliveryAreas(areaId) {
    this.branchService.getDeliveryAreaById(areaId).subscribe(
      (response: any) => {
        this.paths = JSON.parse(response.latLong);
        this.show = true;
        this.lat = parseFloat(response.centerLat);
        this.lng = parseFloat(response.centerLong);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onMapReady(map) {
    if (this.areaID) {
      this.setMapCurrentPosition(this.lat, this.lng);
    }
    this.initDrawingManager(map);
  }

  initDrawingManager = (map: any) => {
    const self = this;
    const options = {};
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    if (this.areaID) {
      this.drawingManager.setOptions({
        drawingControl: false,
      });
    } else {
      this.drawingManager.setOptions({
        drawingControl: true,
        drawingControlOptions: {
          drawingModes: ['polygon'],
        },
        polygonOptions: {
          draggable: true,
          editable: true,
        },
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
      });
    }

    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
              if (!event.overlay.drag) {
                self.updatePointList(event.overlay.getPath());
              }
            });
            google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
              self.updatePointList(event.overlay.getPath());
            });
            google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {
              self.updatePointList(event.overlay.getPath());
            });
          }
          self.updatePointList(event.overlay.getPath());
          this.selectedShape = event.overlay;
          this.selectedShape.type = event.type;
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );
  };

  private setMapCurrentPosition(lat, lng) {
    if (lat && lng) {
      // when edit area
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = parseFloat(lat);
          this.lng = parseFloat(lng);
        });
      }
    } else {
      // when add area
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        });
      }
    }
  }

  deleteSelectedShape() {
    if (this.areaID) {
      this.paths = [];
      this.drawingManager.setOptions({
        drawingControl: true,
        drawingControlOptions: {
          drawingModes: ['polygon'],
        },
        polygonOptions: {
          draggable: true,
          editable: true,
        },
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
      });
    }

    if (this.selectedShape) {
      this.selectedShape.setMap(null);
      this.selectedArea = 0;
      this.pointList = [];
      // To show:
      this.drawingManager.setOptions({
        drawingControl: true,
      });
    }
  }

  updatePointList(path) {
    // work on add
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(path.getAt(i).toJSON());
    }
    this.polygonCenter(this.pointList); // center latLng add this.centerLat
    this.selectedArea = JSON.stringify(this.pointList);
  }

  polytest(event) {
    // work on drag when update
    this.updateArea = true;
    this.pointList = event.newArr[0];
  }

  save() {
    if (this.areaID) {
      console.log("areaId:", this.areaID);
      this.polygonCenter(this.pointList); // center latLng add this.centerLat

      var dataUpdate = {
        uid: this.areaID,
        Address: 'BranchDeliveryAreas 123 789',
        centerLat: this.centerLat,
        centerLong: this.centerLong,
        latLong: JSON.stringify(this.pointList),
      };
      console.log(" [update api] dataUpdate:", dataUpdate);
      this.updateDeliveryArea(dataUpdate); // update
    } else {
      var dataInsert = {
        selectedArea: this.selectedArea,
        centerLat: this.centerLat,
        centerLong: this.centerLong,
        latlong: JSON.stringify(this.pointList),
      };
      console.log("[add api]dataUpdate:", dataUpdate);

      this.addDeliveryArea(dataInsert);
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ update
  // ------------------------------------------------------------------------------------------------
  updateDeliveryArea(data) {
    console.log("data: [updateDeliveryArea]", data);
    this.branchService.updateDeliveryArea(data).subscribe(
      (response: any) => {
        if (response.success == true) {
          // updated
          this.notifyService.showSuccess(
            this.translate.instant(OrdersMessages.update),
            this.translate.instant(OrdersMessages.success)
          );
          this.location.back();
        } else if (
          response.success == false &&
          response.code == 'branch_Exist'
        ) {
          // already exist
          this.notifyService.showError(
            this.translate.instant(BranchDetailsMessage.branchExist),
            this.translate.instant(OrdersMessages.error)
          );
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ add
  // ------------------------------------------------------------------------------------------------
  addDeliveryArea(data) {
    this.branchService.addDeliveryArea(data).subscribe(
      (response: any) => {
        if (response.success == true) {
          // updated
          this.branchService.deliveryAreaID = response.data.uid; // add to service file
          this.notifyService.showSuccess(
            this.translate.instant(productMessage.add),
            this.translate.instant(OrdersMessages.success)
          );
          this.router.navigate([this.navigation.ADD_BRANCHES]);
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
        this.location.back();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  back() {
    this.location.back();
  }

  polygonCenter(latlngArray) {
    // put all latitudes and longitudes in arrays
    let latitudes = latlngArray.map((item) => item.lat);
    let longitudes = latlngArray.map((item) => item.lng);
    // sort the arrays low to high
    latitudes.sort();
    longitudes.sort();

    // get the min and max of each
    const lowX = latitudes[0];
    const highX = latitudes[latitudes.length - 1];
    const lowy = longitudes[0];
    const highy = longitudes[latitudes.length - 1];

    // center of the polygon is the starting point plus the midpoint
    const centerX = lowX + (highX - lowX) / 2;
    const centerY = lowy + (highy - lowy) / 2;
    this.centerLat = centerX;
    this.centerLong = centerY;
  }
}
