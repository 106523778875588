import { Component, EventEmitter, Input, Output } from '@angular/core';

import { routes } from '../../../../consts';
import { User } from '../../../../pages/auth/models';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  @Input() user: User;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  user_info : any;

  isIframeOpen = false;
  iFrameUrl = "https://lace-triangle-d93.notion.site/7980c01b8df84e4bb571d459ca1b04cb?v=352d938d63fc41509ba51f7e174f5ed3"
  // iFrameUrl = "https://app-dev.broetchen.taxi/delivery-type"

  constructor(private translate: TranslateService, private router: Router, private route: ActivatedRoute, private notifyService : NotificationService) {
    const currentLanguage = this.translate.getBrowserLang();
    translate.setDefaultLang(currentLanguage);
    this.user_info = JSON.parse(window.localStorage.getItem('appcmsUser')); //Getting local storage
  }

  public routes: typeof routes = routes;
  public flatlogicEmail: string = "https://flatlogic.com";

  public signOutEmit(): void {
    this.notifyService.showSuccess(this.translate.instant('pages.common.inputValidation.logoutSuccess'), "Success");
    this.signOut.emit();
    this.router.navigate(['/']);
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  onClickHelp(): void {
    const helpUrl = this.iFrameUrl;
    window.open(helpUrl, '_blank');
  }

  openIframe(): void {
    this.isIframeOpen = true;
  }

  closeIframe(): void {
    this.isIframeOpen = false;
  }

}
